var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    attrs: {
      "id": "mnotify__form"
    }
  }, [_vm._m(0), _c('div', {
    ref: "formContainer",
    attrs: {
      "id": "mNotify__form_fields_container"
    }
  }, [_c('div', {
    staticClass: "mnotify__form_upper_link"
  }, [_c('p', {
    staticClass: "text_right_corner"
  }, [_vm._v("Don’t have an account? "), _c('router-link', {
    staticClass: "account_link",
    attrs: {
      "to": "/account/signup"
    }
  }, [_vm._v("Sign Up")])], 1)]), _vm._m(1)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    attrs: {
      "id": "mNotify__form_image_container"
    }
  }, [_c('div', {
    staticClass: "side-image-background"
  }, [_c('div', {
    staticClass: "logo_container"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/logo.png"),
      "alt": "mNotify logo"
    }
  })])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mnotify_form__inner__wrapper"
  }, [_c('div', {
    staticClass: "mNotify__form_fields_inner_container"
  }, [_c('h2', {
    staticClass: "form_title"
  }, [_vm._v("Refer friends. Get rewards.")]), _c('hr', {
    staticClass: "form_line"
  }), _c('p', {
    staticClass: "reset_password_text pb-4"
  }, [_vm._v("Introduce a friend to mNotify to earn "), _c('strong', [_vm._v("$10")]), _vm._v(" worth of credit")]), _c('form', {
    attrs: {
      "action": ""
    }
  }, [_c('label', {
    staticClass: "form_input_label share_link_label",
    attrs: {
      "for": "share"
    }
  }, [_vm._v("Share my referral code with friends")]), _c('div', {
    staticClass: "form_input_share_container"
  }, [_c('input', {
    staticClass: "form_input_share",
    attrs: {
      "type": "text",
      "placeholder": "https://apps.mnotify.net/referral/dorcas"
    }
  }), _c('button', [_c('img', {
    attrs: {
      "width": "25",
      "height": "25",
      "src": require("../../assets/Stroke 1.png"),
      "alt": "img"
    }
  })])]), _c('div', {
    staticClass: "return_to_login"
  }, [_c('p', {
    staticClass: "share_your_code"
  }, [_vm._v("Share Your Code")])]), _c('div', {
    staticClass: "share_icons"
  }, [_c('button', [_c('img', {
    attrs: {
      "width": "20px",
      "height": "20px",
      "src": require("../../assets/whatssap.png"),
      "alt": "WhatsApp logo"
    }
  })]), _c('button', [_c('img', {
    attrs: {
      "width": "20px",
      "height": "20px",
      "src": require("../../assets/messenger.png"),
      "alt": "Messenger logo"
    }
  })]), _c('button', [_c('img', {
    attrs: {
      "width": "20px",
      "height": "20px",
      "src": require("../../assets/twitter.png"),
      "alt": "Twitter logo"
    }
  })]), _c('button', [_c('img', {
    attrs: {
      "src": require("../../assets/dot.png"),
      "alt": "round shaped button"
    }
  }), _c('img', {
    attrs: {
      "src": require("../../assets/dot.png"),
      "alt": "round shaped button"
    }
  }), _c('img', {
    attrs: {
      "src": require("../../assets/dot.png"),
      "alt": "round shaped button"
    }
  })])])])])]);
}];
export { render, staticRenderFns };
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "main-container"
  }, [_c('div', {
    attrs: {
      "id": "slider"
    }
  }, [_c('transition-group', {
    staticClass: "slides-group",
    attrs: {
      "tag": "div",
      "name": _vm.transitionName
    }
  }, [_vm.show ? _c('div', {
    key: _vm.current,
    staticClass: "slide"
  }, [_c(_vm.slides[_vm.current], {
    tag: "component"
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "btn btn-prev",
    attrs: {
      "aria-label": "Previous slide"
    },
    on: {
      "click": function ($event) {
        return _vm.slide(-1);
      }
    }
  }, [_vm._v(" ❮ ")]), _c('div', {
    staticClass: "btn btn-next",
    attrs: {
      "aria-label": "Next slide"
    },
    on: {
      "click": function ($event) {
        return _vm.slide(1);
      }
    }
  }, [_vm._v(" ❯ ")])], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
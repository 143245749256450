var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "notranslate"
  }, [_c('div', {
    staticClass: "__silder_wraper"
  }, [_c('div', {
    staticClass: "slider_text"
  }, [_c('h2', {
    staticClass: "mb-4",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('auth.sliders.slide_3_header'))
    }
  }), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('auth.sliders.slide_3_text'))
    }
  })]), _vm._m(0)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "slider_image"
  }, [_c('img', {
    staticClass: "image",
    attrs: {
      "src": require("../../../assets/img/Sent-Message-bro.png"),
      "alt": "illustrative image of sending a message",
      "width": "1",
      "height": "1"
    }
  })]);
}];
export { render, staticRenderFns };
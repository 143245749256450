import SliderOne from "./animate_sections/Slider_one.vue";
// import SliderTwo from './animate_sections/slider_two'
import SliderThree from "./animate_sections/slider_3.vue";
import SliderFour from "./animate_sections/slider_4.vue";
import Slider from "./animate_sections/Slider.vue";
export default {
  components: {
    SliderOne: SliderOne,
    // SliderTwo:SliderTwo,
    SliderThree: SliderThree,
    SliderFour: SliderFour,
    "main-slider": Slider
  },
  data() {
    return {
      sliderComponents: [SliderOne, SliderThree, SliderFour],
      timer: null,
      currentIndex: 0
    };
  },
  methods: {
    startSlide: function () {
      this.timer = setInterval(this.next, 5000);
    },
    next: function () {
      this.currentIndex += 1;
    },
    prev: function () {
      this.currentIndex -= 1;
    }
  },
  computed: {
    currentComponentfunction() {
      return this.sliderComponents[Math.abs(this.currentIndex) % this.sliderComponents.length];
    }
  },
  mounted: function () {
    this.startSlide();
  }
};
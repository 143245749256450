var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    attrs: {
      "id": "mNotify__refer_a_friend"
    }
  }, [_c('div', {
    staticClass: "mNotify__refer_a_friend_header"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/logo.png"),
      "alt": "mNotify logo"
    }
  }), _c('p', {
    staticClass: "link_text",
    staticStyle: {
      "float": "right"
    }
  }, [_vm._v("Don’t have an account? "), _c('router-link', {
    staticClass: "account_link",
    attrs: {
      "to": "/signup"
    }
  }, [_vm._v("Sign Up")])], 1)]), _vm._m(0)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mNotify_refer_a_friend_box"
  }, [_c('div', {
    staticClass: "box__header"
  }, [_c('div', {}, [_c('h2', {
    staticClass: "share_referal_header_title"
  }, [_vm._v("Refer friends. Get rewards.")]), _c('p', {
    staticClass: "share_referal_header_text"
  }, [_vm._v("Introduce a friend to mNotify to earn $10 worth of credit")])])]), _c('div', {
    staticClass: "box_content pt-5"
  }, [_c('form', {
    attrs: {
      "action": ""
    }
  }, [_c('label', {
    staticClass: "form_input_label share_link_label text-center",
    attrs: {
      "for": "share"
    }
  }, [_vm._v("Share my referral code with friends")]), _c('div', {
    staticClass: "form_input_share_container"
  }, [_c('input', {
    staticClass: "form_input_share",
    attrs: {
      "type": "text",
      "placeholder": "https://apps.mnotify.net/referral/dorcas"
    }
  }), _c('button', [_c('img', {
    attrs: {
      "width": "25",
      "height": "25",
      "src": require("../../assets/Stroke 1.png"),
      "alt": "img"
    }
  })])]), _c('div', {
    staticClass: "return_to_login"
  }, [_c('p', {
    staticClass: "share_your_code_2 text-center"
  }, [_vm._v("Share Your Code")])]), _c('div', {
    staticClass: "share_icons text-center"
  }, [_c('button', [_c('img', {
    attrs: {
      "width": "20px",
      "height": "20px",
      "src": require("../../assets/whatssap.png"),
      "alt": "WhatsApp logo"
    }
  })]), _c('button', [_c('img', {
    attrs: {
      "width": "20px",
      "height": "20px",
      "src": require("../../assets/messenger.png"),
      "alt": "Messenger logo"
    }
  })]), _c('button', [_c('img', {
    attrs: {
      "width": "20px",
      "height": "20px",
      "src": require("../../assets/twitter.png"),
      "alt": "Twitter logo"
    }
  })]), _c('button', [_c('img', {
    attrs: {
      "src": require("../../assets/dot.png"),
      "alt": "round shape button"
    }
  }), _c('img', {
    attrs: {
      "src": require("../../assets/dot.png"),
      "alt": "round shape button"
    }
  }), _c('img', {
    attrs: {
      "src": require("../../assets/dot.png"),
      "alt": "round shape button"
    }
  })])])])])]);
}];
export { render, staticRenderFns };
import SliderOne from './Slider_one.vue';
import SliderThree from './slider_3.vue';
import SliderFour from './slider_4.vue';
export default {
  components: {
    SliderOne: SliderOne,
    SliderThree: SliderThree,
    SliderFour: SliderFour
  },
  data() {
    return {
      current: 0,
      direction: 1,
      transitionName: "fade",
      show: false,
      slides: [SliderOne, SliderThree, SliderFour],
      interval: null
    };
  },
  methods: {
    slide(dir) {
      this.direction = dir;
      dir === 1 ? this.transitionName = "slide-next" : this.transitionName = "slide-prev";
      var len = this.slides.length;
      this.current = (this.current + dir % len + len) % len;
      // Stop interval for 1 second to prevent spamming
      clearInterval(this.interval);
      this.interval = setInterval(() => this.slide(1), 5000);
    }
  },
  mounted() {
    this.show = true;
  },
  beforeMount() {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        this.interval = setInterval(() => this.slide(1), 5000);
      } else {
        clearInterval(this.interval);
      }
    });
  }
};
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "slider_wrapper"
  }, [_c('div', {
    staticClass: "slider_text"
  }, [_c('h2', {
    staticClass: "mb-4 is-relative notranslate",
    staticStyle: {
      "margin-bottom": "0"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.$t('auth.sliders.slide_1_header'))
    }
  }), _c('p', {
    staticClass: "notranslate text-long"
  }, [_vm._v(" " + _vm._s(_vm.$t("auth.sliders.slide_1_text")) + " ")])]), _vm._m(0)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "slider_image"
  }, [_c('img', {
    staticClass: "image",
    staticStyle: {
      "animation-duration": "0s"
    },
    attrs: {
      "src": require("../../../assets/img/Call-center-bro.png"),
      "alt": "illustrative image of a call center",
      "width": "1",
      "height": "1"
    }
  })]);
}];
export { render, staticRenderFns };
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.push.js";
import store from "@/state/store.js";
import AuthWrapper from "./AuthWrapper.vue";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (!this.formData.phone) {
        callback(new Error("Please phone number is required."));
      } else {
        callback();
      }
    };
    return {
      phoneData: Object,
      formattedPhone: "",
      enterAuthCode: false,
      isEditing: false,
      formData: {
        phone: "",
        code: ""
      },
      rules: {
        phone: [{
          validator: validatePass,
          trigger: "change"
        }, {
          pattern: /\+?\d+/g,
          message: "(+) sign is not required."
        }],
        code: [{
          required: true,
          message: "Enter password verification code."
        }, {
          max: 4,
          message: "Password verification code shouldn't be more than 4 digits."
        }]
      },
      loading: false,
      success: "",
      errors: "",
      type: "alert-danger"
    };
  },
  beforeMount() {
    window.addEventListener("beforeunload", event => {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = "";
    });
  },
  computed: {
    countryCode() {
      return store.getters["auth/countryCode"];
    }
  },
  components: {
    AuthWrapper: AuthWrapper
  },
  methods: {
    handleCountryChange(payload) {
      this.phoneData = payload;
      return this.phoneData;
    },
    resetPassword() {
      store.dispatch("auth/getResetPasswordVerificationCode", this.formData).then(response => {
        if (response) {
          this.loading = false;
          this.$router.push("/signin").catch(err => {
            if (err.name !== "NavigationDuplicated") throw err;
          });
          // Set true to show reset password window
          store.commit("auth/SET_RESET_WINDOW_VISIBLE", true);
        }
      }).catch(err => {
        var _err$response;
        this.loading = false;
        if (err.response.status === 404) {
          this.errors = this.$t("auth.forgot_password.form.otp.invalid") || err.response.data.message;
        }
        if (((_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.data) == undefined) {
          this.errors = "Network Error.";
        }
      });
    },
    getPasswordVerification() {
      store.dispatch("auth/resetPassword", this.formData).then(response => {
        if (response) {
          store.dispatch("uistate/setResetPasswordSuccessMessage", {
            phone: this.formData.phone
          });
          this.loading = false;
          this.enterAuthCode = true;
          this.isEditing = true;
        }
        // Set needs to reset password to 1 to trigger
        localStorage.setItem("needsToResetPassword", 1);
      }).catch(err => {
        var _err$response2;
        this.loading = false;
        if (err.response.status === 404) {
          this.errors = this.$t("auth.forgot_password.form.phone.does_not_exist") || err.response.data.message;
        }
        if (((_err$response2 = err.response) === null || _err$response2 === void 0 ? void 0 : _err$response2.data) == undefined) {
          this.errors = "Network Error.";
        }
      });
    },
    submitForm(formName) {
      this.errors = "";
      this.success = "";
      // Set Phone Number
      this.formData.phone = `${this.phoneData.countryCallingCode}${this.phoneData.nationalNumber}`;
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.loading = true;
          return this.enterAuthCode ? this.resetPassword() : this.getPasswordVerification();
        } else {
          return false;
        }
      });
    },
    changeRouter() {
      store.dispatch("uistate/setResetPasswordSuccessMessage", {
        phone: ""
      });
      this.$router.push("/signin").catch(err => {
        if (err.name !== "NavigationDuplicated") throw err;
      });
    }
  }
};
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "col-md-12 main-section"
  }, [_c('div', {
    staticClass: "mnotify-card",
    attrs: {
      "id": "account-settings"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "mnotify-card-body"
  }, [_c('div', {
    staticClass: "row profile-settings-form",
    staticStyle: {
      "margin-top": "2em"
    }
  }, [_c('div', {
    staticClass: "profile_form"
  }, [_c('div', {
    staticClass: "profile-inner"
  }, [_c('el-form', {
    ref: "ruleForm",
    attrs: {
      "model": _vm.formData,
      "rules": _vm.rules
    },
    nativeOn: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.checkEnter.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "form_input_container"
  }, [_c('el-form-item', {
    attrs: {
      "label": "New Password",
      "prop": "password"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "password",
      "show-password": "",
      "autocomplete": "off",
      "placeholder": "New Password"
    },
    model: {
      value: _vm.formData.password,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "password", $$v);
      },
      expression: "formData.password"
    }
  })], 1)], 1), _c('div', {
    staticClass: "form_input_container"
  }, [_c('el-form-item', {
    attrs: {
      "label": "Confirm Password",
      "prop": "password_confirmation"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "password",
      "show-password": "",
      "autocomplete": "off",
      "placeholder": "Confirm Password"
    },
    model: {
      value: _vm.formData.password_confirmation,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "password_confirmation", $$v);
      },
      expression: "formData.password_confirmation"
    }
  })], 1)], 1), _vm.errors ? _c('Alert', {
    attrs: {
      "message": _vm.errors,
      "type": _vm.type
    }
  }) : _vm._e(), _c('div', {
    staticClass: "terms_and_conditons_container mt-5"
  }, [_c('el-form-item', [_c('el-button', {
    attrs: {
      "id": "mNotify_form_submit_btn",
      "loading": _vm.loading,
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.submitForm('ruleForm');
      }
    }
  }, [_vm._v("Change Password")])], 1)], 1)], 1)], 1)])])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mnotify-card-header",
    attrs: {
      "id": "side-indicators-content"
    }
  }, [_c('div', {
    staticClass: "profile-settings-section-header"
  }, [_c('div', {
    staticClass: "profile-settings-sub-header"
  }, [_c('h3', {
    staticClass: "mnotify-card-header-text pb-2 my-0"
  }, [_vm._v("Update Your Password")]), _c('p', {
    staticClass: "profile-description",
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("You must update your password before logging in ")])])])]);
}];
export { render, staticRenderFns };
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    attrs: {
      "id": "mnotify__form"
    }
  }, [_vm._m(0), _c('div', {
    ref: "formContainer",
    attrs: {
      "id": "mNotify__form_fields_container"
    }
  }, [_c('div', {
    staticClass: "mnotify__form_upper_link"
  }, [_c('p', {
    staticClass: "text_right_corner"
  }, [_vm._v("Don’t have an account? "), _c('router-link', {
    staticClass: "account_link",
    attrs: {
      "to": "/account/signup"
    }
  }, [_vm._v("Sign Up")])], 1)]), _c('div', {
    staticClass: "mnotify_form__inner__wrapper"
  }, [_c('div', {
    staticClass: "mNotify__form_fields_inner_container"
  }, [_c('h2', {
    staticClass: "form_title"
  }, [_vm._v("Verify your account")]), _c('hr', {
    staticClass: "form_line"
  }), _c('p', {
    staticClass: "reset_password_text pb-2"
  }, [_vm._v("How would you want to recieve your authentication code ")]), _vm._m(1), _c('form', {
    attrs: {
      "action": ""
    }
  }, [_c('p', {
    staticClass: "enter_auth_code"
  }, [_vm._v("Please enter the authentication code")]), _vm._m(2), _vm._m(3), _c('div', {
    staticClass: "text-center return_to_login"
  }, [_c('router-link', {
    attrs: {
      "to": "/account/login"
    }
  }, [_vm._v(" Return to sign in")])], 1)])])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    attrs: {
      "id": "mNotify__form_image_container"
    }
  }, [_c('div', {
    staticClass: "side-image-background"
  }, [_c('div', {
    staticClass: "logo_container"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/logo.png"),
      "alt": "mNotify logo"
    }
  })])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "form_input_container form_message_type mb-3 mt-2 text-center"
  }, [_c('input', {
    staticClass: "message_type",
    attrs: {
      "type": "text",
      "placeholder": "Text message"
    }
  }), _c('input', {
    staticClass: "message_type",
    attrs: {
      "type": "text",
      "placeholder": "Voice message"
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "form_input_container tel_input text-center"
  }, [_c('input', {
    staticClass: "reset_pin input_text_padding_right",
    attrs: {
      "type": "text",
      "placeholder": ""
    }
  }), _c('input', {
    staticClass: "reset_pin input_text_padding_right",
    attrs: {
      "type": "text",
      "placeholder": ""
    }
  }), _c('input', {
    staticClass: "reset_pin input_text_padding_right_long",
    attrs: {
      "type": "text",
      "placeholder": ""
    }
  }), _c('input', {
    staticClass: "reset_pin input_text_padding_right",
    attrs: {
      "type": "text",
      "placeholder": ""
    }
  }), _c('input', {
    staticClass: "reset_pin input_text_padding_right",
    attrs: {
      "type": "text",
      "placeholder": ""
    }
  }), _c('input', {
    staticClass: "reset_pin",
    attrs: {
      "type": "text",
      "placeholder": ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('button', {
    staticClass: "form_action_btn"
  }, [_vm._v("Login")])]);
}];
export { render, staticRenderFns };
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "auth_wrapper",
    class: [_vm.$route.path !== '/signup' ? 'full-screen-width' : '']
  }, [_c('div', {
    staticClass: "side_wrapper_with_animation"
  }, [_vm._m(0), _c('div', {
    staticClass: "slider__inner"
  }, [_c('main-slider')], 1)]), _vm._m(1), _c('div', [_vm.$route.path.includes('signin') ? _c('changeAppLanguage') : _vm._e(), _c('div', {
    staticClass: "form_secton"
  }, [_vm._t("default")], 2)], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "logo_inner"
  }, [_c('img', {
    attrs: {
      "id": "logo",
      "src": require("../../assets/img/bms-01-text.png"),
      "alt": "img"
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "logo_inner",
    attrs: {
      "id": "logo-small"
    }
  }, [_c('img', {
    attrs: {
      "id": "logo",
      "src": require("../../assets/img/bms-01-text.png"),
      "alt": "img"
    }
  })]);
}];
export { render, staticRenderFns };
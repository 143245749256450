var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    attrs: {
      "id": "mnotify__form"
    }
  }, [_vm._m(0), _c('div', {
    ref: "formContainer",
    attrs: {
      "id": "mNotify__form_fields_container"
    }
  }, [_c('div', {
    staticClass: "mnotify__form_upper_link"
  }, [_c('p', {
    staticClass: "text_right_corner"
  }, [_vm._v("Don’t have an account? "), _c('router-link', {
    staticClass: "account_link",
    attrs: {
      "to": "/account/signup"
    }
  }, [_vm._v("Sign Up")])], 1)]), _c('div', {
    staticClass: "mnotify_form__inner__wrapper"
  }, [_c('div', {
    staticClass: "mNotify__form_fields_inner_container"
  }, [_c('h2', {
    staticClass: "form_title"
  }, [_vm._v("Reset your password")]), _c('p', {
    staticClass: "reset_password_text"
  }, [_vm._v("Enter the phone number associated with your account and we’ll send you a link to reset your password")]), _c('hr', {
    staticClass: "form_line"
  }), _c('form', {
    attrs: {
      "action": ""
    }
  }, [_vm._m(1), _vm._m(2), _c('div', {
    staticClass: "text-center return_to_login mt-2"
  }, [_c('router-link', {
    attrs: {
      "to": "/account/login"
    }
  }, [_vm._v(" Return to sign in")])], 1)])])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    attrs: {
      "id": "mNotify__form_image_container"
    }
  }, [_c('div', {
    staticClass: "side-image-background"
  }, [_c('div', {
    staticClass: "logo_container"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/mnotify logo.svg"),
      "height": "30",
      "width": "120",
      "alt": "img"
    }
  })])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "form_input_container tel_input"
  }, [_c('label', {
    staticClass: "form_input_label",
    attrs: {
      "for": "phone"
    }
  }, [_vm._v("Phone Number")]), _c('select', {
    staticClass: "form_country_code_select",
    attrs: {
      "name": "code",
      "id": "code"
    }
  }), _c('input', {
    staticClass: "form_country_code_phone_number",
    attrs: {
      "type": "text",
      "placeholder": "Enter your phone number"
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('button', {
    staticClass: "form_action_btn"
  }, [_vm._v("Reset")])]);
}];
export { render, staticRenderFns };
import "core-js/modules/es.error.cause.js";
import appConfig from '@/app.config';
import store from '@/state/store';
export default {
  page: {
    title: 'change password',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (this.formData.password_confirmation !== this.formData.password) {
        callback(new Error('Password do not match'));
      } else {
        callback();
      }
    };
    return {
      errors: '',
      loading: false,
      type: 'alert-danger',
      formData: {
        password: "",
        password_confirmation: "",
        phone: ""
      },
      rules: {
        password: [{
          required: true,
          message: 'Password is required.',
          trigger: ['blur', 'change']
        }, {
          min: 6,
          message: 'Password must be at least 6 characters.',
          trigger: 'blur'
        }],
        password_confirmation: [{
          validator: validatePass2,
          trigger: 'blur'
        }, {
          required: true,
          message: 'Please re-type your password',
          trigger: 'change'
        }]
      }
    };
  },
  props: {
    ResetPhoneNumber: {
      type: String,
      required: true
    }
  },
  methods: {
    submitForm(formName) {
      this.errors = '';
      this.loading = true;
      this.$refs[formName].validate(valid => {
        if (valid) {
          // Get Phone number here
          this.formData.phone = this.$props.ResetPhoneNumber;
          store.dispatch('auth/sendNewPasswordToServer', this.formData).then(response => {
            this.loading = false;
            this.$notify({
              title: 'Success',
              message: response.data.message,
              type: 'success'
            });
            // use mutation state to hide temporary reset window
            // set default values to form data
            this.formData.password = "";
            this.formData.password_confirmation = "";
            this.formData.phone = "";
            this.$refs[formName].resetFields();
            store.commit('auth/SET_RESET_WINDOW_VISIBLE', false);
          }).catch(err => {
            this.loading = false;
            if (err.response.data == undefined) {
              this.errors = 'Network error try again';
            }
            if (err.response && err.response.status === 404) {
              this.errors = err.response.data.data.error;
            }
          });
        } else {
          this.loading = false;
          return false;
        }
      });
    }
  }
};
import "core-js/modules/es.array.push.js";
import Storage from "../../services/storage.js";
var storage = new Storage();
export default {
  data() {
    return {
      active: "",
      showMessageDark: true,
      showCallingDark: true,
      checkBox: false
    };
  },
  computed: {
    checkShowMessageDark() {
      if (this.active != "sms") {
        if (this.showMessageDark == true) {
          return true;
        } else return false;
      }
      return false;
    },
    checkShowVoiceDark() {
      if (this.active != "voice") {
        if (this.showCallingDark == true) {
          return true;
        } else return false;
      }
      return false;
    },
    isFrenchUser() {
      return storage.getSavedState("currentUser").country == "CI";
    },
    user() {
      return storage.getSavedState("currentUser");
    }
  },
  methods: {
    returnToDashboard() {
      let storage = new Storage();
      storage.saveAndDelete("defaultLoginType", this.checkBox);
      storage.saveAndDelete("loginType", this.active == "sms" ? "sms" : "voice_messages");
      this.$router.push("/overview").catch(err => {});
    },
    setActive(type) {
      if (type == "sms") {
        this.active = "sms";
        this.showMessageDark = false;
        this.showCallingDark = true;
        sessionStorage.setItem("userDefault", "sms");
      } else if (type != "" && type != "sms") {
        this.active = "voice";
        this.showCallingDark = false;
        this.showMessageDark = true;
        sessionStorage.setItem("userDefault", "voice_messages");
      }
    },
    handleKeyPress(e) {
      if (e.keyCode == 13 && this.active != "") {
        this.returnToDashboard();
      }
    }
  },
  mounted() {
    document.addEventListener("keyup", this.handleKeyPress);
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.handleKeyPress);
  }
};